// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---node-modules-gatsby-plugin-offline-app-shell-js": () => import("/opt/build/repo/node_modules/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---node-modules-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-jsx": () => import("/opt/build/repo/src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-about-us-jsx": () => import("/opt/build/repo/src/pages/about-us.jsx" /* webpackChunkName: "component---src-pages-about-us-jsx" */),
  "component---src-pages-call-back-jsx": () => import("/opt/build/repo/src/pages/call-back.jsx" /* webpackChunkName: "component---src-pages-call-back-jsx" */),
  "component---src-pages-contact-us-jsx": () => import("/opt/build/repo/src/pages/contact-us.jsx" /* webpackChunkName: "component---src-pages-contact-us-jsx" */),
  "component---src-pages-how-it-works-small-businesses-jsx": () => import("/opt/build/repo/src/pages/how-it-works/small-businesses.jsx" /* webpackChunkName: "component---src-pages-how-it-works-small-businesses-jsx" */),
  "component---src-pages-how-it-works-sole-traders-jsx": () => import("/opt/build/repo/src/pages/how-it-works/sole-traders.jsx" /* webpackChunkName: "component---src-pages-how-it-works-sole-traders-jsx" */),
  "component---src-pages-how-it-works-start-ups-jsx": () => import("/opt/build/repo/src/pages/how-it-works/start-ups.jsx" /* webpackChunkName: "component---src-pages-how-it-works-start-ups-jsx" */),
  "component---src-pages-how-it-works-switching-accountants-jsx": () => import("/opt/build/repo/src/pages/how-it-works/switching-accountants.jsx" /* webpackChunkName: "component---src-pages-how-it-works-switching-accountants-jsx" */),
  "component---src-pages-index-jsx": () => import("/opt/build/repo/src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-pricing-build-your-package-jsx": () => import("/opt/build/repo/src/pages/pricing/build-your-package.jsx" /* webpackChunkName: "component---src-pages-pricing-build-your-package-jsx" */),
  "component---src-pages-pricing-small-business-accounting-jsx": () => import("/opt/build/repo/src/pages/pricing/small-business-accounting.jsx" /* webpackChunkName: "component---src-pages-pricing-small-business-accounting-jsx" */),
  "component---src-pages-pricing-specialised-services-jsx": () => import("/opt/build/repo/src/pages/pricing/specialised-services.jsx" /* webpackChunkName: "component---src-pages-pricing-specialised-services-jsx" */),
  "component---src-pages-pricing-standalone-services-jsx": () => import("/opt/build/repo/src/pages/pricing/standalone-services.jsx" /* webpackChunkName: "component---src-pages-pricing-standalone-services-jsx" */),
  "component---src-pages-privacy-policy-jsx": () => import("/opt/build/repo/src/pages/privacy-policy.jsx" /* webpackChunkName: "component---src-pages-privacy-policy-jsx" */),
  "component---src-pages-terms-of-use-jsx": () => import("/opt/build/repo/src/pages/terms-of-use.jsx" /* webpackChunkName: "component---src-pages-terms-of-use-jsx" */)
}

